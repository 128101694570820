import { Component, Input } from '@angular/core';

@Component({
  selector: 'ocf-step-text',
  template: ` <div *ngIf="panelsTexts && !!panelsTexts[Key]" [innerHTML]="panelsTexts[Key] | parseHtmlDeprecated"></div> `,
  styles: [
    `
      :host:empty {
        display: none;
      }
    `,
  ],
  standalone: false,
})
export class StepTextComponent {
  @Input()
  panelsTexts: { [key: string]: string } = {};

  @Input()
  step: number = 0;

  @Input()
  top: boolean = false;

  get Key(): string {
    return `__${this.step}__${this.top ? 'top' : 'bottom'}`;
  }
}
